<template>
	<main class="missing padding-24 bp-1024:padding-64">
		<h2 class="header-font font-size-36 margin-0 margin-b-24 text-brand">
			Page Not Found
		</h2>
		<championships
			template="ChampionshipsList"
			:featured="true"
			sectionTitle="Featured Championships"
		/>
	</main>
</template>

<script>
import Championships from '@/components/championships/Championships';

export default {
	name: 'Missing',

	components: {
		Championships,
	},

	created() {
		this.setMetaSidearm('Page Not Found');
	},
};
</script>

<style lang="scss" scoped></style>
